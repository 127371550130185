const sources = {
    config: {
        base: 'https://tiles.anrijs.lv/',
    },
    groups: [
        {
            name: 'Datu Slāņi',
            layers: [
                {
                    name: 'dodies.lv Takas',
                    code: 'lvtaka',
                    type: 'DodiesLvLayer',
                    url: 'https://dodies.lv/',
                    isDefault: true,
                    extras: {
                        geojsonUrl: 'json/lvtaka.geojson',
                        aprakstsBaseUrl: 'https://dodies.lv/',
                        routesUrl: 'dodies/routes/',
                        print: false,
                        jnx: false,
                    },
                },
                {
                    name: 'dodies.lv Torņi',
                    code: 'lvtornis',
                    type: 'DodiesLvLayer',
                    url: 'https://dodies.lv/',
                    isDefault: true,
                    extras: {
                        geojsonUrl: 'json/lvtornis.geojson',
                        aprakstsBaseUrl: 'https://dodies.lv/',
                        print: false,
                        jnx: false,
                    },
                },
                {
                    name: 'dodies.lv Pikniki',
                    code: 'lvpikniks',
                    type: 'DodiesLvLayer',
                    url: 'https://dodies.lv/',
                    isDefault: true,
                    extras: {
                        geojsonUrl: 'json/lvpikniks.geojson',
                        aprakstsBaseUrl: 'https://dodies.lv/',
                        print: false,
                        jnx: false,
                    },
                },
                {
                    name: 'TKS-93 karšu nomenklatūras lapas',
                    code: 'tks',
                    type: 'LatviaTopoGrid',
                    isDefault: true,
                    extras: {
                        print: false,
                        jnx: false,
                    },
                },
                {
                    name: 'Kadastrs OpenData',
                    zoom: 21,
                    code: 'kad',
                    type: 'WMS',
                    url: 'https://lvmgeoserver.lvm.lv/geoserver/ows?',
                    extras: {
                        layers: 'publicwfs:Kadastra_karte',
                        format: 'image/png',
                        transparent: true,
                        attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                                    'Kadastra informācijas sistēmas atvērtie telpiskie dati. ' +
                                    'WMS servisu nodrošina LVMGEO. CC BY 4.0',
                    },
                },
                {
                    years: '1928',
                    name: 'Robežpunkti LV-EE',
                    scale: '1:25K',
                    code: 'robeza',
                    zoom: 15,
                    type: 'RobezpunktiLayer',
                    url: '1928_lv_ee_robezpunkti/',
                },
                {
                    years: '1936',
                    name: 'Robeža LV-PL',
                    scale: '1:4K',
                    code: 'lvpl',
                    zoom: 18,
                    url: '1936_lv_pl_robeza/{z}/{x}/{y}.png',
                },
                {
                    name: 'Ziemeļvidzemes noklusētie stāsti',
                    code: 'zv',
                    url: 'vestures_stasti/',
                    type: 'VesturesStastiLayer',
                    extras: {
                        notes: "Slānis\"Ziemeļvidzemes noklusētie stāsti\" veidots, lai publiski saglabātu " +
                            "pieeju Igaunijas - Latvijas programmas projekta \"Neapzinātās kultūras mantojuma " +
                            "vērtības kopējā dabas un kultūras telpā\" ietvaros apsekoto objektu datubāzi. " +
                            "Vairāk par projektu <a href=\"https://www.daba.gov.lv/public/lat/projekti/" +
                            "istenotie_projekti/interreg_neapzinatais_kulturas_mantojums/#Aktualitates\">" +
                            "DAP mājaslapā</a>, kā arī <a href=\"https://home.dodies.lv/tiles/vmd-bildes/" +
                            "VMD_Ziemelvidzemes_stasti.pdf\">grāmata PDF formātā</a>"
                    },
                }
            ]
        },
        {
            name: 'Latvijas Izdevumi',
            layers: [
                {
                    years: '1931-1940',
                    name: 'Latvijas armijas',
                    scale: '1:200K',
                    code: 'lva200k',
                    zoom: 13,
                    url: 'LVARM_40_200k/{z}/{x}/{y}.png',
                    extras: {
                        bbox: [
                            {
                                name: '43_Riga_1932.jpg',
                                year: '1932',
                                bbox: [
                                    [
                                        '56.999766703805854',
                                        '23.999583203820844'
                                    ],
                                    [
                                        '55.99991700228173',
                                        '23.999949742268527'
                                    ],
                                    [
                                        '55.999748690232764',
                                        '25.50014615427086'
                                    ],
                                    [
                                        '57.00002841736773',
                                        '25.500580487092886'
                                    ]
                                ]
                            },
                            {
                                name: '35_Petseri_1933.jpg',
                                year: '1933',
                                bbox: [
                                    [
                                        '58.000001463536954',
                                        '27.000425601388475'
                                    ],
                                    [
                                        '56.99977772362391',
                                        '27.000005297052134'
                                    ],
                                    [
                                        '56.999999998562856',
                                        '28.499999999584627'
                                    ],
                                    [
                                        '58.00000000131333',
                                        '28.500000000661803'
                                    ]
                                ]
                            },
                            {
                                name: '33_Cesis_1940.jpg',
                                year: '1940',
                                bbox: [
                                    [
                                        '58.02467309094779',
                                        '25.4652811108593'
                                    ],
                                    [
                                        '58.03889038403056',
                                        '25.42028716682558'
                                    ],
                                    [
                                        '58.04122954411569',
                                        '25.38020380837234'
                                    ],
                                    [
                                        '58.06265798573912',
                                        '25.357039778529618'
                                    ],
                                    [
                                        '58.07608509980159',
                                        '25.338670496842443'
                                    ],
                                    [
                                        '58.09399378914418',
                                        '25.31083091325313'
                                    ],
                                    [
                                        '58.092926010100896',
                                        '25.2721562351912'
                                    ],
                                    [
                                        '58.07971791868634',
                                        '25.267112662605925'
                                    ],
                                    [
                                        '58.07901617468063',
                                        '25.253750712901113'
                                    ],
                                    [
                                        '58.06363789588085',
                                        '25.24899792339847'
                                    ],
                                    [
                                        '58.05334451745131',
                                        '25.252156347587015'
                                    ],
                                    [
                                        '58.034051927074856',
                                        '25.28450995823905'
                                    ],
                                    [
                                        '58.019075626331414',
                                        '25.283333440841933'
                                    ],
                                    [
                                        '58.017257897263555',
                                        '25.278203057052867'
                                    ],
                                    [
                                        '58.0076830002943',
                                        '25.283098179539095'
                                    ],
                                    [
                                        '58.006808832495004',
                                        '25.277663656947592'
                                    ],
                                    [
                                        '58.00123046989672',
                                        '25.280365380378917'
                                    ],
                                    [
                                        '58.001168806092345',
                                        '25.273323828759533'
                                    ],
                                    [
                                        '58.01168104213608',
                                        '25.269963149228694'
                                    ],
                                    [
                                        '58.01079913701097',
                                        '25.260520294927616'
                                    ],
                                    [
                                        '58.012795215660795',
                                        '25.258176985463365'
                                    ],
                                    [
                                        '58.01648702725415',
                                        '25.264101558286935'
                                    ],
                                    [
                                        '58.02482968057683',
                                        '25.26307766920138'
                                    ],
                                    [
                                        '58.03323553463801',
                                        '25.250890370465957'
                                    ],
                                    [
                                        '58.03540974154052',
                                        '25.238902143214045'
                                    ],
                                    [
                                        '58.037760695418314',
                                        '25.237755675368334'
                                    ],
                                    [
                                        '58.04293575393366',
                                        '25.245119876612197'
                                    ],
                                    [
                                        '58.05063254494792',
                                        '25.24765673033292'
                                    ],
                                    [
                                        '58.06423271840023',
                                        '25.246514834403172'
                                    ],
                                    [
                                        '58.064583935407036',
                                        '25.23665107336073'
                                    ],
                                    [
                                        '58.0669382586374',
                                        '25.234635851665857'
                                    ],
                                    [
                                        '58.07176150818875',
                                        '25.2437338008369'
                                    ],
                                    [
                                        '58.08784169460387',
                                        '25.234498343138267'
                                    ],
                                    [
                                        '58.10358092633766',
                                        '25.193983627233326'
                                    ],
                                    [
                                        '58.08783017951968',
                                        '25.17199438532105'
                                    ],
                                    [
                                        '58.08818663964791',
                                        '25.162453079836595'
                                    ],
                                    [
                                        '58.09077869686391',
                                        '25.155554588105915'
                                    ],
                                    [
                                        '58.09336433897039',
                                        '25.12739023433378'
                                    ],
                                    [
                                        '58.08852325688855',
                                        '25.08370672753538'
                                    ],
                                    [
                                        '58.078504748280494',
                                        '25.08333440118745'
                                    ],
                                    [
                                        '58.07863334892479',
                                        '25.065338368357285'
                                    ],
                                    [
                                        '58.027820536582034',
                                        '25.00957611099869'
                                    ],
                                    [
                                        '58.02170853064783',
                                        '24.99076948620154'
                                    ],
                                    [
                                        '58.02206480692459',
                                        '24.948355098495902'
                                    ],
                                    [
                                        '58.00017543827981',
                                        '24.88224330004215'
                                    ],
                                    [
                                        '58.0001158235233',
                                        '24.81857754801161'
                                    ],
                                    [
                                        '58.00433975712898',
                                        '24.812778059837044'
                                    ],
                                    [
                                        '58.00023429638392',
                                        '24.781728507963336'
                                    ],
                                    [
                                        '57.99976601089277',
                                        '24.000002847484815'
                                    ],
                                    [
                                        '56.99999900425947',
                                        '24.000419589773948'
                                    ],
                                    [
                                        '56.99999899931175',
                                        '25.500422626702903'
                                    ],
                                    [
                                        '57.999999999047844',
                                        '25.500433694623595'
                                    ]
                                ]
                            },
                            {
                                name: '31_Ventspils_1940.jpg',
                                year: '1940',
                                bbox: [
                                    [
                                        '58.0000013394618',
                                        '21.000278480292334'
                                    ],
                                    [
                                        '56.99999999951797',
                                        '20.99999999935867'
                                    ],
                                    [
                                        '56.99999999960104',
                                        '22.499999999180908'
                                    ],
                                    [
                                        '58.00000000057476',
                                        '22.50000000000167'
                                    ]
                                ]
                            },
                            {
                                name: '45_Rezekne_1935.jpg',
                                year: '1935',
                                bbox: [
                                    [
                                        '56.99634724805868',
                                        '27.000010728322433'
                                    ],
                                    [
                                        '55.999971466863826',
                                        '27.00010130664783'
                                    ],
                                    [
                                        '55.99952654333353',
                                        '28.499878113502533'
                                    ],
                                    [
                                        '56.99593383329463',
                                        '28.500207856828276'
                                    ]
                                ]
                            },
                            {
                                name: '32_Talsi_1940.jpg',
                                year: '1940',
                                bbox: [
                                    [
                                        '58.00000000009382',
                                        '22.500000000089532'
                                    ],
                                    [
                                        '57.00008332115124',
                                        '22.499997906111737'
                                    ],
                                    [
                                        '57.00000074979498',
                                        '24.000421530213703'
                                    ],
                                    [
                                        '58.00000074634181',
                                        '24.000433220622757'
                                    ]
                                ]
                            },
                            {
                                name: '55_Kraslava_1937.jpg',
                                year: '1937',
                                bbox: [
                                    [
                                        '56.000000000098495',
                                        '27.00000000044374'
                                    ],
                                    [
                                        '55.50000000130195',
                                        '27.00000000119824'
                                    ],
                                    [
                                        '55.49988760944235',
                                        '28.500096927057257'
                                    ],
                                    [
                                        '55.99985591615402',
                                        '28.500399571109693'
                                    ]
                                ]
                            },
                            {
                                name: '54_Daugavpils_1940.jpg',
                                year: '1940',
                                bbox: [
                                    [
                                        '55.99999999992032',
                                        '25.499999999777685'
                                    ],
                                    [
                                        '55.49999999952095',
                                        '25.499999999277108'
                                    ],
                                    [
                                        '55.49999999998957',
                                        '26.999999999474912'
                                    ],
                                    [
                                        '56.00000000004658',
                                        '26.999999999857945'
                                    ]
                                ]
                            },
                            {
                                name: '41_Liepaja_1940.jpg',
                                year: '1940',
                                bbox: [
                                    [
                                        '57.000000000387914',
                                        '21.000000001099927'
                                    ],
                                    [
                                        '55.999944498275596',
                                        '21.00029439506641'
                                    ],
                                    [
                                        '55.99984288670261',
                                        '22.4966828534758'
                                    ],
                                    [
                                        '57.000076552723975',
                                        '22.495841153867712'
                                    ]
                                ]
                            },
                            {
                                name: '42_Jelgava_1939.jpg',
                                year: '1939',
                                bbox: [
                                    [
                                        '57.00000003284155',
                                        '22.500105069106702'
                                    ],
                                    [
                                        '56.00002800053612',
                                        '22.50015313050475'
                                    ],
                                    [
                                        '55.999916737378804',
                                        '24.000151847426178'
                                    ],
                                    [
                                        '56.99985398309767',
                                        '24.000207109193916'
                                    ]
                                ]
                            },
                            {
                                name: '34_Valka_1935.jpg',
                                year: '1935',
                                bbox: [
                                    [
                                        '58.00000000001385',
                                        '25.50000000032765'
                                    ],
                                    [
                                        '56.9999159324415',
                                        '25.500315959959657'
                                    ],
                                    [
                                        '56.9999989994224',
                                        '27.000421891236027'
                                    ],
                                    [
                                        '58.0000292360046',
                                        '27.00021760081671'
                                    ]
                                ]
                            },
                            {
                                name: '44_Jekabpils_1931.jpg',
                                year: '1931',
                                bbox: [
                                    [
                                        '57.000000000339966',
                                        '25.50000000006563'
                                    ],
                                    [
                                        '55.999916639649',
                                        '25.50000202870233'
                                    ],
                                    [
                                        '55.99980573540087',
                                        '27.000355545343638'
                                    ],
                                    [
                                        '57.0000597410466',
                                        '27.000369224114483'
                                    ]
                                ]
                            }
                        ],
                    },
                },
                {
                    years: '1920-1940',
                    name: 'Latvijas armijas',
                    scale: '1:75K',
                    code: 'lva75k',
                    zoom: 13,
                    isDefault: true,
                    url: 'LVARM_40_75k/{z}/{x}/{y}.png',
                    extras: {
                        bbox: [
                            {
                                name: 'Lat075_008_Ovisi_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '57.762893816651946',
                                        '21.485527627476813'
                                    ],
                                    [
                                        '57.49358974341537',
                                        '21.488962851335128'
                                    ],
                                    [
                                        '57.49418097387107',
                                        '22.010860297986934'
                                    ],
                                    [
                                        '57.76324223510458',
                                        '22.012104940664457'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_009_Ventspils_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '57.51304612308443',
                                        '21.48374967966939'
                                    ],
                                    [
                                        '57.24499175575821',
                                        '21.49024809424826'
                                    ],
                                    [
                                        '57.247750137715215',
                                        '22.015625381563773'
                                    ],
                                    [
                                        '57.515325892737394',
                                        '22.01176456490467'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_010_Piltene_ca1930.jpg',
                                year: '1931',
                                bbox: [
                                    [
                                        '57.26886853047636',
                                        '21.49019032491835'
                                    ],
                                    [
                                        '56.9953794378739',
                                        '21.47956237693434'
                                    ],
                                    [
                                        '56.98828547572985',
                                        '22.00930345827883'
                                    ],
                                    [
                                        '57.26173701806813',
                                        '22.022500576496732'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_011_Kuldiga_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '57.01337338138943',
                                        '21.486488978948614'
                                    ],
                                    [
                                        '56.74439616280306',
                                        '21.487145636414223'
                                    ],
                                    [
                                        '56.743749593278324',
                                        '22.010992997331375'
                                    ],
                                    [
                                        '57.01238697791578',
                                        '22.01400543036003'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_012_Aizpute_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '56.763805564188644',
                                        '21.489945998644558'
                                    ],
                                    [
                                        '56.49631415609764',
                                        '21.485578490797106'
                                    ],
                                    [
                                        '56.49385743573826',
                                        '22.00860496759066'
                                    ],
                                    [
                                        '56.76111856003021',
                                        '22.015745177417756'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_013_Priekule_ca1930.jpg',
                                year: '1930',
                                bbox: [
                                    [
                                        '56.5149838959162',
                                        '21.488971736458662'
                                    ],
                                    [
                                        '56.24596336871648',
                                        '21.483305003953326'
                                    ],
                                    [
                                        '56.24203763516266',
                                        '22.01053354870217'
                                    ],
                                    [
                                        '56.5114395012115',
                                        '22.019518918293496'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_015_Dundaga_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '57.763521052581716',
                                        '21.987645825681984'
                                    ],
                                    [
                                        '57.49544660276477',
                                        '21.988636726484387'
                                    ],
                                    [
                                        '57.4936644958368',
                                        '22.50888146167891'
                                    ],
                                    [
                                        '57.7631756172466',
                                        '22.511603068496512'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_016_Ugale_ca1930.jpg',
                                year: '1939',
                                bbox: [
                                    [
                                        '57.51583870958842',
                                        '21.98851855183716'
                                    ],
                                    [
                                        '57.246755458909064',
                                        '21.980324795543687'
                                    ],
                                    [
                                        '57.24224610272891',
                                        '22.51061500921363'
                                    ],
                                    [
                                        '57.5113074586675',
                                        '22.522196433500447'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_017_Usma_ca1930.jpg',
                                year: '1930',
                                bbox: [
                                    [
                                        '57.26498032645122',
                                        '21.99013592836129'
                                    ],
                                    [
                                        '56.99481726077103',
                                        '21.98931699213373'
                                    ],
                                    [
                                        '56.992937384142685',
                                        '22.50603685079177'
                                    ],
                                    [
                                        '57.26318119372778',
                                        '22.51133663378082'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_018_Kabile_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '57.01381029860044',
                                        '21.987724542626623'
                                    ],
                                    [
                                        '56.743305862597666',
                                        '21.98903954450707'
                                    ],
                                    [
                                        '56.74373915150346',
                                        '22.511840969536742'
                                    ],
                                    [
                                        '57.01359533728477',
                                        '22.513491356538577'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_019_Saldus_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '56.76246087483416',
                                        '21.984155872315515'
                                    ],
                                    [
                                        '56.49113445908484',
                                        '21.990670392408035'
                                    ],
                                    [
                                        '56.49443142336978',
                                        '22.51402456487065'
                                    ],
                                    [
                                        '56.76536728905791',
                                        '22.51084430774125'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_020_Ezere_ca1930.jpg',
                                year: '1930',
                                bbox: [
                                    [
                                        '56.514549107041255',
                                        '21.984100313624484'
                                    ],
                                    [
                                        '56.24248795465866',
                                        '21.987431951283867'
                                    ],
                                    [
                                        '56.24483697761407',
                                        '22.511892792868395'
                                    ],
                                    [
                                        '56.51491827470942',
                                        '22.510494287239972'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_021_Roja_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '57.80294980305553',
                                        '22.487595438194568'
                                    ],
                                    [
                                        '57.49499029785883',
                                        '22.490585863959247'
                                    ],
                                    [
                                        '57.49640160505745',
                                        '23.01225784784434'
                                    ],
                                    [
                                        '57.80361803347357',
                                        '23.01363218034498'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_022_Valdemarpils_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '57.51258694313725',
                                        '22.486090866387382'
                                    ],
                                    [
                                        '57.24395110088482',
                                        '22.489548223659718'
                                    ],
                                    [
                                        '57.243764005644216',
                                        '23.009913191196333'
                                    ],
                                    [
                                        '57.515537333508085',
                                        '23.010100059050092'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_023_Talsi_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '57.26384781769301',
                                        '22.487587534725407'
                                    ],
                                    [
                                        '56.9946623867862',
                                        '22.486792356726365'
                                    ],
                                    [
                                        '56.99246010696443',
                                        '23.011156614675038'
                                    ],
                                    [
                                        '57.26158799234977',
                                        '23.016479649374734'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_024_Zemite_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '57.013932190224565',
                                        '22.49027355329465'
                                    ],
                                    [
                                        '56.74544910521924',
                                        '22.48877174302782'
                                    ],
                                    [
                                        '56.743941951900894',
                                        '23.00592326374199'
                                    ],
                                    [
                                        '57.01175047286157',
                                        '23.011250966244674'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_025_Blidiene_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '56.76451110511423',
                                        '22.489637078800815'
                                    ],
                                    [
                                        '56.496151305632665',
                                        '22.487482315811572'
                                    ],
                                    [
                                        '56.49383985093057',
                                        '23.007967033278828'
                                    ],
                                    [
                                        '56.762803512747155',
                                        '23.013172510744745'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_026_Auce_ca1930.jpg',
                                year: '1930',
                                bbox: [
                                    [
                                        '56.51303293993794',
                                        '22.48864473029454'
                                    ],
                                    [
                                        '56.24498388465143',
                                        '22.488946455805475'
                                    ],
                                    [
                                        '56.244578226152846',
                                        '23.009652570598348'
                                    ],
                                    [
                                        '56.512554679363184',
                                        '23.013147618611708'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_027_Mersrags_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '57.51381720194012',
                                        '22.988082262586932'
                                    ],
                                    [
                                        '57.24451303338758',
                                        '22.98815808269104'
                                    ],
                                    [
                                        '57.24369045489885',
                                        '23.51057821038405'
                                    ],
                                    [
                                        '57.512653691668035',
                                        '23.51384318160818'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_028_Engure_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '57.264424878440906',
                                        '22.98771259369614'
                                    ],
                                    [
                                        '56.99611484173276',
                                        '22.986759928200357'
                                    ],
                                    [
                                        '56.99474628229442',
                                        '23.50907514773526'
                                    ],
                                    [
                                        '57.263229727900665',
                                        '23.512636434440495'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_029_Tukums_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '57.01339820252067',
                                        '22.987155337080175'
                                    ],
                                    [
                                        '56.744274528131164',
                                        '22.99103275925857'
                                    ],
                                    [
                                        '56.745419366703686',
                                        '23.511965725847137'
                                    ],
                                    [
                                        '57.01365174390234',
                                        '23.50918059578952'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_030_Dobele_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '56.76377484353165',
                                        '22.987448431087227'
                                    ],
                                    [
                                        '56.49381519799941',
                                        '22.990211052984456'
                                    ],
                                    [
                                        '56.49488198873437',
                                        '23.510019801014163'
                                    ],
                                    [
                                        '56.764409542522294',
                                        '23.510416899499795'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_031_Kalnamuiza_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '56.51246952230073',
                                        '22.98451677753121'
                                    ],
                                    [
                                        '56.24294510477747',
                                        '22.990259435785745'
                                    ],
                                    [
                                        '56.245503700344436',
                                        '23.512095392622523'
                                    ],
                                    [
                                        '56.514937658254055',
                                        '23.509035962431916'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_032_Sloka_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '57.05035213970848',
                                        '23.50012576870181'
                                    ],
                                    [
                                        '57.05026088080263',
                                        '23.999997412757615'
                                    ],
                                    [
                                        '56.75000000132651',
                                        '24.00000000203087'
                                    ],
                                    [
                                        '56.75000000303057',
                                        '23.50000000124064'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_033_Jelgava_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '56.760837268458616',
                                        '23.487715562221307'
                                    ],
                                    [
                                        '56.49373568985149',
                                        '23.49032756762515'
                                    ],
                                    [
                                        '56.49437974748273',
                                        '24.00974639075365'
                                    ],
                                    [
                                        '56.76174888368601',
                                        '24.010023739187993'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_034_Platone_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '56.51293119655231',
                                        '23.486841942754335'
                                    ],
                                    [
                                        '56.242086897607194',
                                        '23.49306672624591'
                                    ],
                                    [
                                        '56.2447240800389',
                                        '24.00991955861249'
                                    ],
                                    [
                                        '56.51365669411413',
                                        '24.00701118749243'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_035_Ainazi_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '58.0128808402191',
                                        '23.987373790755864'
                                    ],
                                    [
                                        '57.74347487422196',
                                        '23.990579375250935'
                                    ],
                                    [
                                        '57.743946118090925',
                                        '24.510436321423345'
                                    ],
                                    [
                                        '58.013363646090085',
                                        '24.51144819952142'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_036_Sveiciems_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '57.76118848871384',
                                        '23.98182921917519'
                                    ],
                                    [
                                        '57.489549433120295',
                                        '23.993649546687863'
                                    ],
                                    [
                                        '57.49488277231544',
                                        '24.515206217152954'
                                    ],
                                    [
                                        '57.76633161094876',
                                        '24.507903481156166'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_037_Neibade_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '57.515573976435064',
                                        '23.987705494386745'
                                    ],
                                    [
                                        '57.24548519780004',
                                        '23.987116928133986'
                                    ],
                                    [
                                        '57.244538946339404',
                                        '24.510652661107258'
                                    ],
                                    [
                                        '57.51440867128455',
                                        '24.51531879435286'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_038_Daugavgriva_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '57.263343997699344',
                                        '23.987527317948178'
                                    ],
                                    [
                                        '56.994916682871555',
                                        '23.989557597371135'
                                    ],
                                    [
                                        '56.994081694605995',
                                        '24.510550046588005'
                                    ],
                                    [
                                        '57.26287648983364',
                                        '24.513501633944344'
                                    ]
                                ]
                            },
                            {
                                name: 'LAT075_039_Riga_1929_300dpi.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '57.023419688412325',
                                        '23.969883208665827'
                                    ],
                                    [
                                        '56.72364232638738',
                                        '23.973767519496466'
                                    ],
                                    [
                                        '56.72353934197398',
                                        '24.52910559877941'
                                    ],
                                    [
                                        '57.02395386493041',
                                        '24.52991904174257'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_039_Riga_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '57.01381494781278',
                                        '23.98724437324868'
                                    ],
                                    [
                                        '56.74270548547977',
                                        '23.99233824284155'
                                    ],
                                    [
                                        '56.744534162208005',
                                        '24.5093202293727'
                                    ],
                                    [
                                        '57.015243921420094',
                                        '24.509759986046706'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_040_Baldone_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '56.762709186825205',
                                        '23.989156520305567'
                                    ],
                                    [
                                        '56.49484824036036',
                                        '23.98721329589523'
                                    ],
                                    [
                                        '56.49396177000478',
                                        '24.507873271702447'
                                    ],
                                    [
                                        '56.76174463088796',
                                        '24.512485762881056'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_041_Bauska_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '56.51223317233184',
                                        '23.989190819778358'
                                    ],
                                    [
                                        '56.2450288825174',
                                        '23.98901436601162'
                                    ],
                                    [
                                        '56.244129011954804',
                                        '24.509029051217787'
                                    ],
                                    [
                                        '56.51202579769587',
                                        '24.511284453492944'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_042_Vaski_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '56.26226675643584',
                                        '23.98596452527641'
                                    ],
                                    [
                                        '55.993791578037296',
                                        '23.99078323047314'
                                    ],
                                    [
                                        '55.995905637267676',
                                        '24.508517587623288'
                                    ],
                                    [
                                        '56.26416419863086',
                                        '24.50691371378465'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_043_Kilingi-Nomme_ca1930.jpg',
                                year: '1935',
                                bbox: [
                                    [
                                        '58.26812391523791',
                                        '24.404776989247043'
                                    ],
                                    [
                                        '57.9949963316074',
                                        '24.39979984679719'
                                    ],
                                    [
                                        '57.98967987686215',
                                        '25.01136251776162'
                                    ],
                                    [
                                        '58.26370181678609',
                                        '25.020146236317206'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_044_Aloja_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '58.01039501163824',
                                        '24.485331239448858'
                                    ],
                                    [
                                        '57.74367249424754',
                                        '24.49115480845466'
                                    ],
                                    [
                                        '57.74520741845476',
                                        '25.01158377111481'
                                    ],
                                    [
                                        '58.01203299188679',
                                        '25.010068169630177'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_045_Limbazi_ca1930.jpg',
                                year: '1930',
                                bbox: [
                                    [
                                        '57.75781429143506',
                                        '24.485298436753745'
                                    ],
                                    [
                                        '57.4941656788168',
                                        '24.486388269123307'
                                    ],
                                    [
                                        '57.49256295846422',
                                        '25.01250399772769'
                                    ],
                                    [
                                        '57.75608215116052',
                                        '25.018352205945497'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_046_Straupe_ca1930.jpg',
                                year: '1930',
                                bbox: [
                                    [
                                        '57.505457769795534',
                                        '24.48439967692782'
                                    ],
                                    [
                                        '57.24016535386662',
                                        '24.491341303459127'
                                    ],
                                    [
                                        '57.24227092954329',
                                        '25.01281263579912'
                                    ],
                                    [
                                        '57.50833863727483',
                                        '25.01101328000709'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_047_Sigulda_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '57.262259368920404',
                                        '24.489683751758946'
                                    ],
                                    [
                                        '56.99527480307494',
                                        '24.489112935167334'
                                    ],
                                    [
                                        '56.99380670399011',
                                        '25.007817826639727'
                                    ],
                                    [
                                        '57.260748165079356',
                                        '25.013415926288896'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_048_Ogre_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '57.01148584533229',
                                        '24.48441175949812'
                                    ],
                                    [
                                        '56.74034554899519',
                                        '24.493109554057916'
                                    ],
                                    [
                                        '56.74490644417497',
                                        '25.01372221177148'
                                    ],
                                    [
                                        '57.01561309735838',
                                        '25.008372800180158'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_049_Rembate_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '56.764350531324176',
                                        '24.489240016240803'
                                    ],
                                    [
                                        '56.49501095506251',
                                        '24.48593652221919'
                                    ],
                                    [
                                        '56.49221456828283',
                                        '25.009166328776995'
                                    ],
                                    [
                                        '56.76145138833094',
                                        '25.014813347576432'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_050_Skaistkalne_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '56.51421785618175',
                                        '24.487024528330146'
                                    ],
                                    [
                                        '56.24407073661512',
                                        '24.487899345862093'
                                    ],
                                    [
                                        '56.24310917784522',
                                        '25.01084846526021'
                                    ],
                                    [
                                        '56.513759074193004',
                                        '25.013966452147137'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_051_Birzi_ca1930.jpg',
                                year: '1926',
                                bbox: [
                                    [
                                        '56.265306474276656',
                                        '24.488340045062838'
                                    ],
                                    [
                                        '55.991890141311046',
                                        '24.49222650385846'
                                    ],
                                    [
                                        '55.99367834224979',
                                        '25.01318847952635'
                                    ],
                                    [
                                        '56.26673480873618',
                                        '25.01282839085625'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_052_Moisakula_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '58.2656893022589',
                                        '24.986657240860012'
                                    ],
                                    [
                                        '57.99484961821936',
                                        '24.984145039169338'
                                    ],
                                    [
                                        '57.99264641115196',
                                        '25.51036109206535'
                                    ],
                                    [
                                        '58.26417411110376',
                                        '25.514876674754074'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_053_Rujiena_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '58.013407078502645',
                                        '24.987238774404396'
                                    ],
                                    [
                                        '57.74406622679701',
                                        '24.9889766557839'
                                    ],
                                    [
                                        '57.74371440862744',
                                        '25.507250521521737'
                                    ],
                                    [
                                        '58.01304390244297',
                                        '25.51103152210685'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_054_Valmiera_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '57.76404035232538',
                                        '24.988509834919206'
                                    ],
                                    [
                                        '57.4947046361478',
                                        '24.986986713501004'
                                    ],
                                    [
                                        '57.49359005284722',
                                        '25.508580781742697'
                                    ],
                                    [
                                        '57.762577249814306',
                                        '25.513817253810544'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_055_Cesis_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '57.51525540828339',
                                        '24.989220995937984'
                                    ],
                                    [
                                        '57.24537796483935',
                                        '24.982328521342996'
                                    ],
                                    [
                                        '57.24118342783531',
                                        '25.50566715806191'
                                    ],
                                    [
                                        '57.51039420572087',
                                        '25.515854730385723'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_056_Ieriki_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '57.250000000285226',
                                        '24.999999999744723'
                                    ],
                                    [
                                        '57.24999999945243',
                                        '25.499999997991136'
                                    ],
                                    [
                                        '57.00000000058207',
                                        '25.4999999994446'
                                    ],
                                    [
                                        '57.00000000239216',
                                        '24.999999999685425'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_057_Madliena_ca1930.jpg',
                                year: '1926',
                                bbox: [
                                    [
                                        '57.013562620000506',
                                        '24.985541414461487'
                                    ],
                                    [
                                        '56.742611393624884',
                                        '24.98687189824101'
                                    ],
                                    [
                                        '56.742542711702576',
                                        '25.5118896614386'
                                    ],
                                    [
                                        '57.01382222392001',
                                        '25.513674394104992'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_058_Jaunjelgava_ca1930.jpg',
                                year: '1925',
                                bbox: [
                                    [
                                        '56.76269600066418',
                                        '24.98050404117374'
                                    ],
                                    [
                                        '56.48968408402971',
                                        '24.988470874507446'
                                    ],
                                    [
                                        '56.49366827454474',
                                        '25.516151501244053'
                                    ],
                                    [
                                        '56.765639256802515',
                                        '25.51232771286886'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_059_Daudzese_ca1930.jpg',
                                year: '1926',
                                bbox: [
                                    [
                                        '56.51388027589257',
                                        '24.9891687828695'
                                    ],
                                    [
                                        '56.24389886864904',
                                        '24.992129624199'
                                    ],
                                    [
                                        '56.24449680643518',
                                        '25.512649392074817'
                                    ],
                                    [
                                        '56.514229014388235',
                                        '25.512951977042352'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_060_Nereta_ca1930.jpg',
                                year: '1926',
                                bbox: [
                                    [
                                        '56.26333401619233',
                                        '24.987878299159913'
                                    ],
                                    [
                                        '55.99376309283219',
                                        '24.98972333471622'
                                    ],
                                    [
                                        '55.99357780537176',
                                        '25.511530864693405'
                                    ],
                                    [
                                        '56.26347093556967',
                                        '25.513279544319595'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_062_Ergeme_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '58.01193452298852',
                                        '25.48676868696977'
                                    ],
                                    [
                                        '57.74381355255355',
                                        '25.491024492533697'
                                    ],
                                    [
                                        '57.744758382120615',
                                        '26.01063816949458'
                                    ],
                                    [
                                        '58.01271415759445',
                                        '26.01178734168646'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_063_Strencis_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '57.76175517342066',
                                        '25.48501089808615'
                                    ],
                                    [
                                        '57.49430202468126',
                                        '25.489454350850245'
                                    ],
                                    [
                                        '57.49583740234545',
                                        '26.01021490924481'
                                    ],
                                    [
                                        '57.76326319756773',
                                        '26.00995298525044'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_064_Smiltene_ca1930.jpg',
                                year: '1926',
                                bbox: [
                                    [
                                        '57.511991431541425',
                                        '25.481113515432853'
                                    ],
                                    [
                                        '57.241781396097124',
                                        '25.488305398707773'
                                    ],
                                    [
                                        '57.244749838329696',
                                        '26.013156934647853'
                                    ],
                                    [
                                        '57.51515271118393',
                                        '26.00957075213548'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_065_Dzerbene_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '57.26439197274658',
                                        '25.487381058573103'
                                    ],
                                    [
                                        '56.99484366872241',
                                        '25.486834402501312'
                                    ],
                                    [
                                        '56.992989552138546',
                                        '26.010963554079527'
                                    ],
                                    [
                                        '57.26314011739543',
                                        '26.016913150351062'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_066_Ergli_ca1930.jpg',
                                year: '1925',
                                bbox: [
                                    [
                                        '57.014486346625226',
                                        '25.488158641521885'
                                    ],
                                    [
                                        '56.7423637867816',
                                        '25.491868815490932'
                                    ],
                                    [
                                        '56.74373489383289',
                                        '26.012997916043638'
                                    ],
                                    [
                                        '57.01549173898896',
                                        '26.01285939868376'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_067_Plavinas_ca1930.jpg',
                                year: '1925',
                                bbox: [
                                    [
                                        '56.762202366287696',
                                        '25.483520005224264'
                                    ],
                                    [
                                        '56.49045068126079',
                                        '25.49127601726405'
                                    ],
                                    [
                                        '56.494258203534926',
                                        '26.0146558793203'
                                    ],
                                    [
                                        '56.76595871521397',
                                        '26.01057616488601'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_068_Jekabpils_ca1930.jpg',
                                year: '1925',
                                bbox: [
                                    [
                                        '56.51247417098787',
                                        '25.485218530936912'
                                    ],
                                    [
                                        '56.24378280318856',
                                        '25.488526662328532'
                                    ],
                                    [
                                        '56.24467940517086',
                                        '26.01121840255548'
                                    ],
                                    [
                                        '56.51312811163188',
                                        '26.011290896046905'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_069_Subata_ca1930.jpg',
                                year: '1926',
                                bbox: [
                                    [
                                        '56.262218607086105',
                                        '25.485009438129406'
                                    ],
                                    [
                                        '55.99423622357536',
                                        '25.487381341753206'
                                    ],
                                    [
                                        '55.9946413481783',
                                        '26.01159717434813'
                                    ],
                                    [
                                        '56.263280598572116',
                                        '26.01176428926848'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_070_Rakiski_ca1930.jpg',
                                year: '1926',
                                bbox: [
                                    [
                                        '56.01385813436431',
                                        '25.48731669536246'
                                    ],
                                    [
                                        '55.74542541532212',
                                        '25.48715903870113'
                                    ],
                                    [
                                        '55.744174401752026',
                                        '26.009935923196846'
                                    ],
                                    [
                                        '56.0127183926422',
                                        '26.012625266877595'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_071_Valka_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '58.01261445339109',
                                        '25.986391459102474'
                                    ],
                                    [
                                        '57.74434340827509',
                                        '25.988673043389475'
                                    ],
                                    [
                                        '57.743984234128675',
                                        '26.51081005556328'
                                    ],
                                    [
                                        '58.01244136972556',
                                        '26.5129765652613'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_072_Gaujiena_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '57.764122489686336',
                                        '25.989015584181463'
                                    ],
                                    [
                                        '57.4948889635939',
                                        '25.98669285869972'
                                    ],
                                    [
                                        '57.49228189764081',
                                        '26.507311096195885'
                                    ],
                                    [
                                        '57.762494700763824',
                                        '26.51329309073096'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_073_Palsmanis_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '57.514684699531294',
                                        '25.98653088944838'
                                    ],
                                    [
                                        '57.24448004745837',
                                        '25.986945987114314'
                                    ],
                                    [
                                        '57.24395911987815',
                                        '26.510950639958107'
                                    ],
                                    [
                                        '57.513636915066144',
                                        '26.513302579412247'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_074_Ranka_ca1930.jpg',
                                year: '1926',
                                bbox: [
                                    [
                                        '57.26185751322275',
                                        '25.98875714734393'
                                    ],
                                    [
                                        '56.99443948044158',
                                        '25.989731152453352'
                                    ],
                                    [
                                        '56.99414044605621',
                                        '26.50888077742694'
                                    ],
                                    [
                                        '57.26174552965555',
                                        '26.511433055428448'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_075_Madona_ca1930.jpg',
                                year: '1926',
                                bbox: [
                                    [
                                        '57.01370315787419',
                                        '25.987621698055108'
                                    ],
                                    [
                                        '56.743711796558095',
                                        '25.98759831694445'
                                    ],
                                    [
                                        '56.74329697608223',
                                        '26.50990943443963'
                                    ],
                                    [
                                        '57.01327924855818',
                                        '26.513720671521344'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_076_Lijograde_ca1930.jpg',
                                year: '1925',
                                bbox: [
                                    [
                                        '56.76479147530058',
                                        '25.982072640634495'
                                    ],
                                    [
                                        '56.49134938860655',
                                        '25.988924854932424'
                                    ],
                                    [
                                        '56.49385559454376',
                                        '26.514291947540816'
                                    ],
                                    [
                                        '56.766903348703686',
                                        '26.512494348759823'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_077_Livani_ca1930.jpg',
                                year: '1925',
                                bbox: [
                                    [
                                        '56.514976440685075',
                                        '25.987233378088693'
                                    ],
                                    [
                                        '56.24352259083812',
                                        '25.98677135057515'
                                    ],
                                    [
                                        '56.24190332885092',
                                        '26.511485567799195'
                                    ],
                                    [
                                        '56.51378356087421',
                                        '26.51584867718669'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_078_Bebrene_ca1930.jpg',
                                year: '1925',
                                bbox: [
                                    [
                                        '56.264133164821395',
                                        '25.983578893384124'
                                    ],
                                    [
                                        '55.99331634709531',
                                        '25.98933104741075'
                                    ],
                                    [
                                        '55.99562687678357',
                                        '26.511868784381356'
                                    ],
                                    [
                                        '56.26656058523399',
                                        '26.509951507309026'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_079_Ilukste_ca1930.jpg',
                                year: '1925',
                                bbox: [
                                    [
                                        '56.014244322153964',
                                        '25.985092830168693'
                                    ],
                                    [
                                        '55.74414581608269',
                                        '25.987832276636116'
                                    ],
                                    [
                                        '55.7443240335332',
                                        '26.51088164063861'
                                    ],
                                    [
                                        '56.01442692526085',
                                        '26.51240047286947'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_080_Ezereni_ca1930.jpg',
                                year: '1924',
                                bbox: [
                                    [
                                        '55.76489333216607',
                                        '25.986742624692578'
                                    ],
                                    [
                                        '55.4917688339033',
                                        '25.993215246620707'
                                    ],
                                    [
                                        '55.49544061612751',
                                        '26.512753350274508'
                                    ],
                                    [
                                        '55.76796645828371',
                                        '26.508845902583822'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_081_Ape_ca1930.jpg',
                                year: '1934',
                                bbox: [
                                    [
                                        '57.764076047085',
                                        '26.48857915692327'
                                    ],
                                    [
                                        '57.494332065422164',
                                        '26.487380711450392'
                                    ],
                                    [
                                        '57.49308366590186',
                                        '27.050177083655157'
                                    ],
                                    [
                                        '57.76197117295251',
                                        '27.05472374759117'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_082_Lejasciems_ca1930.jpg',
                                year: '1932',
                                bbox: [
                                    [
                                        '57.51298326061926',
                                        '26.486749982682998'
                                    ],
                                    [
                                        '57.24076198441111',
                                        '26.494805732200845'
                                    ],
                                    [
                                        '57.24529513834131',
                                        '27.01714581097336'
                                    ],
                                    [
                                        '57.517560273964854',
                                        '27.010768335445412'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_083_Gulbene_ca1930.jpg',
                                year: '1931',
                                bbox: [
                                    [
                                        '57.26400249489355',
                                        '26.488270937270638'
                                    ],
                                    [
                                        '56.99459275796964',
                                        '26.485781567041247'
                                    ],
                                    [
                                        '56.99298811210649',
                                        '27.009772535095966'
                                    ],
                                    [
                                        '57.26230217600504',
                                        '27.015546617094234'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_084_Lubana_ca1930.jpg',
                                year: '1925',
                                bbox: [
                                    [
                                        '57.01457421848272',
                                        '26.48401787574264'
                                    ],
                                    [
                                        '56.74208717063404',
                                        '26.490853899421033'
                                    ],
                                    [
                                        '56.74474010212234',
                                        '27.01208073843709'
                                    ],
                                    [
                                        '57.01737612610864',
                                        '27.010603459086127'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_085_Varaklani_ca1930.jpg',
                                year: '1925',
                                bbox: [
                                    [
                                        '56.76509554762545',
                                        '26.487809827016385'
                                    ],
                                    [
                                        '56.493121286775114',
                                        '26.489228976154713'
                                    ],
                                    [
                                        '56.49456955689402',
                                        '27.00935128861679'
                                    ],
                                    [
                                        '56.76539118412334',
                                        '27.01063375032414'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_086_Preili_ca1930.jpg',
                                year: '1925',
                                bbox: [
                                    [
                                        '56.51521548381461',
                                        '26.482823900955815'
                                    ],
                                    [
                                        '56.24151484034759',
                                        '26.487235800925614'
                                    ],
                                    [
                                        '56.24438156553069',
                                        '27.00779034910668'
                                    ],
                                    [
                                        '56.516891897152604',
                                        '27.007059321882238'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_087_Viski_ca1930.jpg',
                                year: '1933',
                                bbox: [
                                    [
                                        '56.26388379107435',
                                        '26.48476893592001'
                                    ],
                                    [
                                        '55.99410091442069',
                                        '26.48961203362176'
                                    ],
                                    [
                                        '55.99552176122835',
                                        '27.012599229960266'
                                    ],
                                    [
                                        '56.26593150231706',
                                        '27.011805181542588'
                                    ]
                                ]
                            },
                            {
                                name: 'LAT075_088_Daugavpils_1924_300dpi.jpg',
                                year: '1924',
                                bbox: [
                                    [
                                        '56.013803416842606',
                                        '26.485677151865364'
                                    ],
                                    [
                                        '55.74169206132985',
                                        '26.492731746855856'
                                    ],
                                    [
                                        '55.744456557236624',
                                        '27.011572216236114'
                                    ],
                                    [
                                        '56.016509162801356',
                                        '27.009011716476422'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_088_Daugavpils_ca1930.jpg',
                                year: '1924',
                                bbox: [
                                    [
                                        '56.01379603893464',
                                        '26.485581781792515'
                                    ],
                                    [
                                        '55.74180970736041',
                                        '26.492525239664754'
                                    ],
                                    [
                                        '55.74457026481825',
                                        '27.011473394074798'
                                    ],
                                    [
                                        '56.01663073762899',
                                        '27.009134070528617'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_089_Tarzeki_ca1930.jpg',
                                year: '1925',
                                bbox: [
                                    [
                                        '55.76353738805328',
                                        '26.48731367651024'
                                    ],
                                    [
                                        '55.49494234961495',
                                        '26.489142791834137'
                                    ],
                                    [
                                        '55.49440874883028',
                                        '27.009737312046735'
                                    ],
                                    [
                                        '55.762991464135034',
                                        '27.011885148629208'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_090_Veclaicene_ca1930.jpg',
                                year: '1934',
                                bbox: [
                                    [
                                        '57.76133744312603',
                                        '26.97744542203077'
                                    ],
                                    [
                                        '57.490297312959',
                                        '26.987693024546264'
                                    ],
                                    [
                                        '57.49501890570096',
                                        '27.514770749050403'
                                    ],
                                    [
                                        '57.766377565282724',
                                        '27.50657414824635'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_091_Aluksne_ca1930.jpg',
                                year: '1932',
                                bbox: [
                                    [
                                        '57.50000000029346',
                                        '27.000000000275556'
                                    ],
                                    [
                                        '57.50000000018787',
                                        '27.499999999635317'
                                    ],
                                    [
                                        '57.24999999988784',
                                        '27.49999999857101'
                                    ],
                                    [
                                        '57.25000000069983',
                                        '26.999999999598533'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_092_Balvi_ca1930.jpg',
                                year: '1932',
                                bbox: [
                                    [
                                        '57.25000000024373',
                                        '26.99999999936534'
                                    ],
                                    [
                                        '57.250000002307466',
                                        '27.500000002953882'
                                    ],
                                    [
                                        '56.999999997587274',
                                        '27.500000000749917'
                                    ],
                                    [
                                        '56.999999995794425',
                                        '26.9999999974763'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_093_Kokoreva_ca1930.jpg',
                                year: '1925',
                                bbox: [
                                    [
                                        '57.01343652728156',
                                        '26.987676047493924'
                                    ],
                                    [
                                        '56.744191979674',
                                        '26.988420267640862'
                                    ],
                                    [
                                        '56.74383247910922',
                                        '27.50956931673197'
                                    ],
                                    [
                                        '57.01320108868981',
                                        '27.511775629580523'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_094_Rezekne_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '56.76517656176938',
                                        '26.98889570745189'
                                    ],
                                    [
                                        '56.49638025121542',
                                        '26.989758862904957'
                                    ],
                                    [
                                        '56.49614476988697',
                                        '27.50958677498168'
                                    ],
                                    [
                                        '56.76493565349058',
                                        '27.512687878727508'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_095_Antonopole_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '56.51469132705528',
                                        '26.989544097294303'
                                    ],
                                    [
                                        '56.24443916646015',
                                        '26.98982673428148'
                                    ],
                                    [
                                        '56.243611217430285',
                                        '27.50949711821065'
                                    ],
                                    [
                                        '56.51384886099519',
                                        '27.512234333729687'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_096_Pusa_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '56.26584007509467',
                                        '26.990450919584998'
                                    ],
                                    [
                                        '55.9974128214015',
                                        '26.987776007475922'
                                    ],
                                    [
                                        '55.99420555027962',
                                        '27.507574165227386'
                                    ],
                                    [
                                        '56.263870078329695',
                                        '27.513178707885007'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_097_Kraslava_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '56.01448298264362',
                                        '26.98635429455431'
                                    ],
                                    [
                                        '55.74494845930456',
                                        '26.987898655249612'
                                    ],
                                    [
                                        '55.74393291449567',
                                        '27.5108859114336'
                                    ],
                                    [
                                        '56.01402776069828',
                                        '27.514308823040444'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_099_Batvina_ca1930.jpg',
                                year: '1934',
                                bbox: [
                                    [
                                        '57.76106258055262',
                                        '27.48234955857254'
                                    ],
                                    [
                                        '57.49383241942932',
                                        '27.489843301788365'
                                    ],
                                    [
                                        '57.497283873178695',
                                        '28.011650711912647'
                                    ],
                                    [
                                        '57.76450661307536',
                                        '28.007025099715104'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_100_Kaceni_ca1930.jpg',
                                year: '1934',
                                bbox: [
                                    [
                                        '57.51273737076682',
                                        '27.490585023264305'
                                    ],
                                    [
                                        '57.24361103642148',
                                        '27.494048348208448'
                                    ],
                                    [
                                        '57.244838736250394',
                                        '28.07599482654368'
                                    ],
                                    [
                                        '57.513983015702166',
                                        '28.075353104166194'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_101_Jaunlategale_ca1930.jpg',
                                year: '1932',
                                bbox: [
                                    [
                                        '57.26428795531745',
                                        '27.48808338596463'
                                    ],
                                    [
                                        '56.99453578139817',
                                        '27.48612229884846'
                                    ],
                                    [
                                        '56.99177386192229',
                                        '28.141312621205216'
                                    ],
                                    [
                                        '57.2612796974614',
                                        '28.14737914829889'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_102_Karsava_ca1930.jpg',
                                year: '1931',
                                bbox: [
                                    [
                                        '57.01339254795024',
                                        '27.486782758037975'
                                    ],
                                    [
                                        '56.74407256689743',
                                        '27.48889656304499'
                                    ],
                                    [
                                        '56.744246725382276',
                                        '28.127357616240708'
                                    ],
                                    [
                                        '57.01370027892587',
                                        '28.12981095427104'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_103_Ludza_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '56.76632083274429',
                                        '27.488370835526855'
                                    ],
                                    [
                                        '56.49578087928835',
                                        '27.48272724138832'
                                    ],
                                    [
                                        '56.49171840956944',
                                        '28.010817739550866'
                                    ],
                                    [
                                        '56.76231789359821',
                                        '28.019564221402355'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_104_Kaunata_ca1930.jpg',
                                year: '1927',
                                bbox: [
                                    [
                                        '56.51418791912219',
                                        '27.487491904425934'
                                    ],
                                    [
                                        '56.24462544045095',
                                        '27.48667137500144'
                                    ],
                                    [
                                        '56.243381761281746',
                                        '28.01040885868727'
                                    ],
                                    [
                                        '56.51259709161953',
                                        '28.01414476160506'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_105_Dagda_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '56.262845706135145',
                                        '27.485629633868694'
                                    ],
                                    [
                                        '55.992703502241596',
                                        '27.48872999218317'
                                    ],
                                    [
                                        '55.99389852538648',
                                        '28.010887353689707'
                                    ],
                                    [
                                        '56.26367707651242',
                                        '28.012086280023563'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_106_Indra_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '56.01208905895167',
                                        '27.484465132750888'
                                    ],
                                    [
                                        '55.74217069661034',
                                        '27.490281757694916'
                                    ],
                                    [
                                        '55.74525374171944',
                                        '28.011735673118217'
                                    ],
                                    [
                                        '56.014840060401376',
                                        '28.0091621309209'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_109_Slabadinci_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '56.76492760779074',
                                        '27.986676706673848'
                                    ],
                                    [
                                        '56.49485550263399',
                                        '27.98653950541165'
                                    ],
                                    [
                                        '56.49384549043156',
                                        '28.509442687355374'
                                    ],
                                    [
                                        '56.76389769510244',
                                        '28.512992192814842'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_110_Zilupe_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '56.51428330900244',
                                        '27.98843315507392'
                                    ],
                                    [
                                        '56.24490686582552',
                                        '27.98754152155889'
                                    ],
                                    [
                                        '56.24313851472794',
                                        '28.509930849184098'
                                    ],
                                    [
                                        '56.51246590789738',
                                        '28.515608195797824'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_111_Polescina_ca1930.jpg',
                                year: '1928',
                                bbox: [
                                    [
                                        '56.262653299279414',
                                        '27.986779382025304'
                                    ],
                                    [
                                        '55.99385642584378',
                                        '27.98972310551814'
                                    ],
                                    [
                                        '55.99491896691735',
                                        '28.50982262185396'
                                    ],
                                    [
                                        '56.263671184676234',
                                        '28.51010494103631'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_002_Libciems_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '57.51275936963773',
                                        '20.98590470609685'
                                    ],
                                    [
                                        '57.244387535906625',
                                        '20.98905057878898'
                                    ],
                                    [
                                        '57.24514905642075',
                                        '21.509644163510348'
                                    ],
                                    [
                                        '57.51373836059703',
                                        '21.51154301791036'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_003_Uzava_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '57.264806436380375',
                                        '20.98747574734005'
                                    ],
                                    [
                                        '56.995033457951834',
                                        '20.986132423005408'
                                    ],
                                    [
                                        '56.99289080483932',
                                        '21.50787127234435'
                                    ],
                                    [
                                        '57.26214487336753',
                                        '21.51454431785005'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_004_Pavilosta_ca1930.jpg',
                                year: '1930',
                                bbox: [
                                    [
                                        '57.0150495971494',
                                        '20.988364341617743'
                                    ],
                                    [
                                        '56.745133990546044',
                                        '20.98545775802276'
                                    ],
                                    [
                                        '56.74136763380987',
                                        '21.505123090463165'
                                    ],
                                    [
                                        '57.01098239954567',
                                        '21.512930198307867'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_005_Liepaja_ca1930.jpg',
                                year: '1929',
                                bbox: [
                                    [
                                        '56.764331388142715',
                                        '20.9551872201308'
                                    ],
                                    [
                                        '56.496605153271986',
                                        '20.953730896380495'
                                    ],
                                    [
                                        '56.49387983567339',
                                        '21.50997785883778'
                                    ],
                                    [
                                        '56.76181489793696',
                                        '21.514759778706086'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_006_Barta_ca1930.jpg',
                                year: '1930',
                                bbox: [
                                    [
                                        '56.51437209470987',
                                        '20.95607537663898'
                                    ],
                                    [
                                        '56.24501401073375',
                                        '20.954499536855522'
                                    ],
                                    [
                                        '56.242359059991514',
                                        '21.50977954105268'
                                    ],
                                    [
                                        '56.511672735345826',
                                        '21.51443403784186'
                                    ]
                                ]
                            },
                            {
                                name: 'Lat075_007_Rucava_ca1930.jpg',
                                year: '1931',
                                bbox: [
                                    [
                                        '56.25819929408846',
                                        '20.93889244266446'
                                    ],
                                    [
                                        '55.991028828782596',
                                        '20.956669666533937'
                                    ],
                                    [
                                        '56.000888462366284',
                                        '21.511544236220722'
                                    ],
                                    [
                                        '56.26925114553899',
                                        '21.497784465326564'
                                    ]
                                ]
                            },
                        ],
                    },
                },
                {
                    years: '1922-1926',
                    name: 'Latvijas armijas',
                    scale: '1:50K',
                    code: 'lva50k',
                    zoom: 14,
                    url: 'LVARM_1922_50k/{z}/{x}/{y}.png',
                },
                {
                    years: '1928-1936',
                    name: 'Latvijas armijas',
                    scale: '1:25K',
                    code: 'lva25k',
                    zoom: 16,
                    isDefault: true,
                    url: 'LVARM_40_25k_v2/{z}/{x}/{y}.png',
                },
                {
                    years: '1918-1919',
                    name: 'Brīvības Cīņas',
                    scale: '',
                    code: 'bc1',
                    zoom: 10,
                    url: '1918_Brivibas_Cinas/1918-1919/{z}/{x}/{y}.png',
                },
                {
                    years: '1919-1920',
                    name: 'Brīvības Cīņas',
                    scale: '',
                    code: 'bc2',
                    zoom: 10,
                    url: '1918_Brivibas_Cinas/1919-1920/{z}/{x}/{y}.png',
                },
                {
                    years: '1921',
                    name: 'Latvijas karte',
                    scale: '1:200K',
                    code: 'lk1',
                    zoom: 12,
                    url: '1921_latvijas_karte/{z}/{x}/{y}.png',
                },
                {
                    years: '1930',
                    name: 'Mērniecības planšetes',
                    scale: '1:5K',
                    code: 'mp',
                    zoom: 17,
                    url: '5k_plansetes/{z}/{x}/{y}.png',
                },
                {
                    years: '1928',
                    name: 'Latvijas satiksmes ceļu Karte',
                    scale: '1:400K',
                    code: 'lsck',
                    zoom: 12,
                    url: '1928_latvijas_satiksmes_celu_karte_400k/{z}/{x}/{y}.png',
                },
                {
                    years: '1931',
                    name: 'Latvijas ceļu un pagastu robežu karte',
                    scale: '1:300K',
                    code: 'lcpk300',
                    zoom: 12,
                    url: '1931_latvijas_celu_un_pagastu_robezu_karte_300k/{z}/{x}/{y}.png',
                },
                {
                    years: '1933',
                    name: 'Jaunlatgales apriņķa ceļu karte',
                    scale: '1:50K',
                    code: 'jlck',
                    zoom: 14,
                    url: '1933_jaunlatgales_celu_karte_50k/{z}/{x}/{y}.png',
                },
                {
                    years: '1934',
                    name: 'Latvijas Ceļu Karte',
                    scale: '1:300K',
                    code: 'lck300',
                    zoom: 12,
                    url: 'autoceli_1934_300k/{z}/{x}/{y}.png',
                },
                {
                    years: '1935',
                    name: 'Latvijas autoceļi',
                    scale: '1:500K',
                    code: 'lac500',
                    zoom: 12,
                    url: '1935_latvijas_autoceli_500k/{z}/{x}/{y}.png',
                },
                {
                    years: '1999',
                    name: 'VZD',
                    scale: '1:50K',
                    code: 'vzd',
                    zoom: 14,
                    url: 'vzd_1999_50k/{z}/{x}/{y}.png',
                },
                {
                    years: '2013',
                    name: 'Latvijas Republikas pārskata karte',
                    scale: '1:250K',
                    code: 'lr1',
                    zoom: 12,
                    url: '2013_LR_parskata_karte_250k/{z}/{x}/{y}.png',
                },
                {
                    years: '2020',
                    name: 'Latvijas Republikas pārskata karte',
                    scale: '1:250K',
                    code: 'lr2',
                    zoom: 12,
                    url: '2020_LR_parskata_karte_250k/{z}/{x}/{y}.png',
                },
            ]
        },
        {
            name: 'Vecās Kartes',
            layers: [
                {
                    years: '17. gs.',
                    name: 'Zviedru muižas',
                    scale: '',
                    code: 'Z',
                    zoom: 16,
                    url: '17gs_muizas/{z}/{x}/{y}.png',
                },
                {
                    years: '17. gs.',
                    name: 'Rīgas patrimoniālā apgabala plāns',
                    scale: '',
                    code: 'ppa',
                    zoom: 13,
                    url: '17gs_rigas_apgabals/{z}/{x}/{y}.png',
                },
                {
                    years: '19.-19. gs.',
                    name: 'PGM',
                    scale: '',
                    code: 'pgm',
                    zoom: 16,
                    url: '18gs_pgm/{z}/{x}/{y}.png',
                },
                {
                    years: '19. gs.',
                    name: 'Popes muiža',
                    scale: '',
                    code: 'pm',
                    zoom: 16,
                    url: 'popes_muiza/{z}/{x}/{y}.png',
                },
                {
                    years: '1908',
                    name: 'Viena versta',
                    scale: '1:42K',
                    code: '1v',
                    zoom: 15,
                    url: '1908_versta_42k/{z}/{x}/{y}.png',
                },
                {
                    years: '1914-1919',
                    name: 'West. Osteuropa',
                    scale: '1:25K',
                    code: 'wo',
                    zoom: 14,
                    url: 'westl25k/{z}/{x}/{y}.png',
                },
                {
                    years: '1910',
                    name: 'Two-verst | West Borderland Territory [Orig]',
                    scale: '1:86K',
                    code: '2va',
                    zoom: 14,
                    url: 'divverstu_86k_orig/{z}/{x}/{y}.png',
                },
                {
                    years: '1939',
                    name: 'Two-verst | West Borderland Territory [RKKA]',
                    scale: '1:86K',
                    code: '2va',
                    zoom: 14,
                    url: 'divverstu_86k_rkka/{z}/{x}/{y}.png',
                },
                {
                    years: '1940',
                    name: 'RKKA',
                    scale: '1:50K',
                    code: 'rk1',
                    zoom: 14,
                    url: '1940_rkka_50k/{z}/{x}/{y}.png',
                },
                {
                    years: '1905',
                    name: 'RKKA',
                    scale: '1:100K',
                    code: 'rk2',
                    zoom: 13,
                    url: '/1905_rkka_100k{z}/{x}/{y}.png',
                },
                {
                    years: '1940',
                    name: 'RKKA',
                    scale: '1:200K',
                    code: 'rk3',
                    zoom: 13,
                    url: '1940_rkka_200k/{z}/{x}/{y}.png',
                },
                {
                    years: '1940',
                    name: 'RKKA',
                    scale: '1:500K',
                    code: 'rk4',
                    zoom: 11,
                    url: '1940_rkka_500k/{z}/{x}/{y}.png',
                },
                {
                    years: '1916',
                    name: 'Feldmäßig hergestellte Fliegerkarte',
                    scale: '1:200K',
                    code: 'fk',
                    zoom: 12,
                    url: '1916_fliegerkarte_200k/{z}/{x}/{y}.png',
                },
                {
                    years: '1941',
                    name: 'Strassenkarte der Baltischen Länder',
                    scale: '1:300K',
                    code: 'sdbl',
                    zoom: 12,
                    url: '1941_Strassenkarte_der_Baltischen_300k/{z}/{x}/{y}.png',
                },
                {
                    years: '1942',
                    name: 'Deutsche Heereskarte',
                    scale: '1:100K',
                    code: 'dh1',
                    zoom: 10,
                    url: '1942_heereskarte_100k/{z}/{x}/{y}.png',
                },
                {
                    years: '1943',
                    name: 'Deutsche Heereskarte',
                    scale: '1:300K',
                    code: 'dh2',
                    zoom: 10,
                    url: '1943_heereskarte_300k/{z}/{x}/{y}.png',
                },
                {
                    years: '1904',
                    name: 'Karte des wistlichen Ruslands',
                    scale: '1:100K',
                    code: 'kdwr',
                    zoom: 10,
                    url: '1904_kdwr_100k{z}/{x}/{y}.png',
                },
                {
                    years: '1924',
                    name: 'WIG',
                    scale: '1:100K',
                    code: 'wig',
                    zoom: 10,
                    url: '1924_wig_100k/{z}/{x}/{y}.png',
                },
                {
                    years: '1917',
                    name: 'Vācu armija',
                    scale: '1:10K',
                    code: 'va1',
                    zoom: 16,
                    url: '1917_vacu_armija_10k/{z}/{x}/{y}.png',
                },
                {
                    years: '1917',
                    name: 'Vācu armija',
                    scale: '1:25K',
                    code: 'va2',
                    zoom: 14,
                    url: '1917_karte_der_8_armee/base/{z}/{x}/{y}.png',
                },
                {
                    years: '1917',
                    name: 'Vācu armija (C)',
                    scale: '1:25K',
                    code: 'va3',
                    zoom: 14,
                    url: '1917_karte_der_8_armee/clean/{z}/{x}/{y}.png',
                },
                {
                    years: '1917',
                    name: 'Vācu armija (D)',
                    scale: '1:25K',
                    code: 'va4',
                    zoom: 14,
                    url: '1917_karte_der_8_armee/dirty/{z}/{x}/{y}.png',
                },
                {
                    years: '1940',
                    name: 'Estland-Lettland',
                    scale: '1:100K',
                    code: 'estlet',
                    zoom: 13,
                    url: '1940_estland_lettland_100k/{z}/{x}/{y}.png',
                },
                {
                    years: '1915',
                    name: 'Operationskarte',
                    scale: '1:800K',
                    code: 'opk',
                    zoom: 11,
                    url: '1915_operationskarte_800k/{z}/{x}/{y}.png',
                },
                {
                    years: '1917',
                    name: 'Karte von Kurland',
                    scale: '1:50K',
                    code: 'kur',
                    zoom: 10,
                    url: '1917_karte_von_kurland_50k/{z}/{x}/{y}.png',
                },
                {
                    years: '1752-1835',
                    name: 'Atlas von Liefland',
                    code: 'L',
                    zoom: 13,
                    url: '1754_atlas_von_liefland/{z}/{x}/{y}.png',
                },
                {
                    years: '1820',
                    name: 'Geograficheskii atlas Rossiiskoi imperii',
                    code: 'L',
                    zoom: 13,
                    url: '1820_geograficheskoi_atlas_rossiiskoi_inperii/{z}/{x}/{y}.png',
                },
                {
                    years: '1839',
                    name: 'Specialkarte von Livland',
                    scale: '1:186K',
                    code: 'S',
                    zoom: 13,
                    url: '1839_specialarte_von_livland_186k/{z}/{x}/{y}.png',
                },
                {
                    years: '1914-1919',
                    name: 'Niemieckie kopie rosyjskiej "trójwiorstówki"',
                    scale: '1:126K',
                    code: 'A',
                    zoom: 14,
                    url: '1915_126k/{z}/{x}/{y}.png',
                },
                {
                    years: '1950',
                    name: 'P. Mantnieka',
                    scale: '1:450K',
                    code: 'S',
                    zoom: 11,
                    url: '1950_P_Mantnieka_450k/{z}/{x}/{y}.png',
                },
                {
                    years: '1933',
                    name: 'P. Mantnieka',
                    scale: '1:250K',
                    code: 'D',
                    zoom: 12,
                    url: '1933_P_Mantnieka_250k/{z}/{x}/{y}.png',
                },
                {
                    years: '1916',
                    name: 'Feldmäßig hergestellte Fliegerkarte',
                    scale: '1:200K',
                    code: 'fl',
                    zoom: 12,
                    url: '1916_fliegerkarte_200k/{z}/{x}/{y}.png',
                },
                {
                    years: '1915',
                    name: 'Ubersichtskarte von Mitteleuropa',
                    scale: '1:300K',
                    code: 'L',
                    zoom: 12,
                    url: 'ubersichtskarte_300k/{z}/{x}/{y}.png',
                },
                {
                    years: '1940',
                    name: 'Latvijas Ceļu Karte',
                    scale: '1:200K',
                    code: 'lvc200',
                    zoom: 12,
                    url: 'autoceli_1940_200k/{z}/{x}/{y}.png',
                },
                {
                    years: '1940',
                    name: 'Estland-Lettland',
                    scale: '1:100K',
                    code: '7',
                    zoom: 13,
                    url: '1940_estland_lettland_100k/{z}/{x}/{y}.png',
                },
                {
                    years: '1941',
                    name: 'Strassenkarte der Baltischen Länder',
                    scale: '1:300K',
                    code: 'el',
                    zoom: 12,
                    url: '1941_Strassenkarte_der_Baltischen_300k/{z}/{x}/{y}.png',
                },
                {
                    years: '1954',
                    name: 'US Army',
                    scale: '1:250K',
                    code: 'us',
                    zoom: 12,
                    url: 'US_ARMY_1954_250k/{z}/{x}/{y}.png',
                },
                {
                    years: '1956-1992',
                    name: 'ASV armijas topogrāfiskā',
                    scale: '1:50k',
                    code: 'us2',
                    zoom: 14,
                    url: '1956_us_ams_50k/{z}/{x}/{y}.png',
                },
                {
                    years: '1905',
                    name: 'Specialnaja karta evropejskoj Rossii',
                    scale: '1:420K',
                    code: 'spri',
                    zoom: 12,
                    url: '1905_specialnaja_karta_evropeiskoj_rosii_420k/{z}/{x}/{y}.png',
                },
                {
                    years: '1884',
                    name: 'Special-Karte von Mittel-Europa',
                    scale: '1:200K',
                    code: 'sk',
                    zoom: 12,
                    url: '1884_specialkarte_von_mitteleuropa_200k/{z}/{x}/{y}.png',
                },
                {
                    years: '1917',
                    name: 'Zusammendruck',
                    scale: '1:100K',
                    code: 'zu',
                    zoom: 14,
                    url: '1917_zusammendruck_100k/{z}/{x}/{y}.png',
                },
                {
                    years: '1918',
                    name: 'Ubersichtskarte der 8. armee',
                    scale: '1:100K',
                    code: 'ub',
                    zoom: 14,
                    url: '1918_ubersichtskarte_der_8_armee_100k/{z}/{x}/{y}.png',
                },
                {
                    years: '1940',
                    name: 'Grosßblatt',
                    scale: '1:100K',
                    code: 'gr',
                    zoom: 14,
                    url: '1940_grosblatt_100k/{z}/{x}/{y}.png',
                },
            ]
        },
        {
            name: 'Aerofoto',
            layers: [
                {
                    years: '1967-1972',
                    name: 'CORONA Satelite',
                    code: 'C',
                    zoom: 15,
                    url: 'corona/{z}/{x}/{y}.png',
                },
                {
                    years: '1940s',
                    name: 'NARA Aerofoto',
                    code: 'N',
                    zoom: 17,
                    url: 'aerofoto_1940/{z}/{x}/{y}.png',
                },
                {
                    name: 'DJI Ortofoto',
                    code: 'dji',
                    zoom: 10,
                    url: 'https://geotiff.anrijs.lv/layers/5c57cdf59e2fa/{z}/{x}/{y}.png',
                },
            ]
        },
        {
            name: 'Citi',
            layers: [
                {
                    years: '1990',
                    name: 'VVM Shēmas',
                    code: 'vvm',
                    zoom: 14,
                    url: 'vvm_shemas_1990/{z}/{x}/{y}.png',
                },
                {
                    years: '1938',
                    name: 'Telefonu vadu tīkla shēma',
                    code: 'tel',
                    zoom: 12,
                    url: '1938_telefonu_vadu_shema/{z}/{x}/{y}.png',
                },
            ]
        },
        {
            name: 'LVM / LGIA L',
            layers: [
                {
                    name: 'LVM DRM / Reljefs',
                    code: 'dvm',
                    type: 'WMS',
                    url: 'https://lvmgeoserver.lvm.lv/geoserver/ows?',
                    extras: {
                        layers: 'public:ZemeLKS',
                        attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                                'Digitālā zemes virsmas modeļa pamatdati &copy; ' +
                                'Latvijas Ģeotelpiskās informācijas aģentūra &copy;',
                    },
                },
                {
                    name: 'LVM DVM / Virsma',
                    code: 'dvm',
                    type: 'WMS',
                    url: 'https://lvmgeoserver.lvm.lv/geoserver/ows?',
                    extras: {
                        layers: 'public:DSM_LKS',
                        attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                                'Digitālā zemes virsmas modeļa pamatdati &copy; ' +
                                'Latvijas Ģeotelpiskās informācijas aģentūra &copy;',
                    },
                },
                {
                    name: 'LVM Ortofoto',
                    code: 'orto',
                    type: 'WMS',
                    url: 'https://lvmgeoserver.lvm.lv/geoserver/ows?',
                    extras: {
                        layers: 'public:Orto_LKS',
                        attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                                'Digitālā zemes virsmas modeļa pamatdati &copy; ' +
                                'Latvijas Ģeotelpiskās informācijas aģentūra &copy;',
                    },
                },
                {
                    years: '1994-1999',
                    name: 'LVM Ortofoto 1. Cikls',
                    code: 'orto1',
                    type: 'WMS',
                    url: 'https://lvmgeoserver.lvm.lv/geoserver/ows?',
                    extras: {
                        layers: 'public:Orto_1cikls',
                        attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                                'Digitālā zemes virsmas modeļa pamatdati &copy; ' +
                                'Latvijas Ģeotelpiskās informācijas aģentūra &copy;',
                    },
                },
                {
                    years: '2003-2005',
                    name: 'LVM Ortofoto 2. Cikls',
                    code: 'orto2',
                    type: 'WMS',
                    url: 'https://lvmgeoserver.lvm.lv/geoserver/ows?',
                    extras: {
                        layers: 'public:Orto_2cikls',
                        attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                                'Digitālā zemes virsmas modeļa pamatdati &copy; ' +
                                'Latvijas Ģeotelpiskās informācijas aģentūra &copy;',
                    },
                },
                {
                    years: '2007-2008',
                    name: 'LVM Ortofoto 3. Cikls',
                    code: 'orto3',
                    type: 'WMS',
                    url: 'https://lvmgeoserver.lvm.lv/geoserver/ows?',
                    extras: {
                        layers: 'public:Orto_3cikls',
                        attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                                'Digitālā zemes virsmas modeļa pamatdati &copy; ' +
                                'Latvijas Ģeotelpiskās informācijas aģentūra &copy;',
                    },
                },
                {
                    years: '2010-2011',
                    name: 'LVM Ortofoto 4. Cikls',
                    code: 'orto4',
                    type: 'WMS',
                    url: 'https://lvmgeoserver.lvm.lv/geoserver/ows?',
                    extras: {
                        layers: 'public:Orto_4cikls',
                        attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                                'Digitālā zemes virsmas modeļa pamatdati &copy; ' +
                                'Latvijas Ģeotelpiskās informācijas aģentūra &copy;',
                    },
                },
                {
                    years: '2013-2015',
                    name: 'LVM Ortofoto 5. Cikls',
                    code: 'orto5',
                    type: 'WMS',
                    url: 'https://lvmgeoserver.lvm.lv/geoserver/ows?',
                    extras: {
                        layers: 'public:Orto_5cikls',
                        attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                                'Digitālā zemes virsmas modeļa pamatdati &copy; ' +
                                'Latvijas Ģeotelpiskās informācijas aģentūra &copy;',
                    },
                },
                {
                    years: '2016-2018',
                    name: 'LVM Ortofoto 6. Cikls',
                    code: 'orto6',
                    type: 'WMS',
                    url: 'https://lvmgeoserver.lvm.lv/geoserver/ows?',
                    extras: {
                        layers: 'public:Orto_6cikls',
                        attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                                'Digitālā zemes virsmas modeļa pamatdati &copy; ' +
                                'Latvijas Ģeotelpiskās informācijas aģentūra &copy;',
                    },
                },
                {
                    name: '1. metra augstumlīknes',
                    code: '1m',
                    url: '1metra/{z}/{x}/{y}.png',
                    extras: {
                        attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                                'Digitālā zemes virsmas modeļa pamatdati &copy; ' +
                                'Latvijas Ģeotelpiskās informācijas aģentūra &copy;',
                    },
                },
            ]
        },
        {
            name: 'Genshtab / Геренальный штаб',
            layers: [
                {
                    years: '1980',
                    name: 'Геренальный штаб (C)',
                    scale: '1:10K',
                    code: 'gen1',
                    zoom: 16,
                    url: 'genshtab_10k_c/{z}/{x}/{y}.png',
                },
                {
                    years: '1980',
                    name: 'Геренальный штаб (O)',
                    scale: '1:10K',
                    code: 'gen2',
                    zoom: 16,
                    url: 'genshtab_10k_o/{z}/{x}/{y}.png',
                },
                {
                    years: '1980',
                    name: 'Геренальный штаб (Pilsētas)',
                    scale: '1:10K',
                    code: 'gen3',
                    zoom: 16,
                    url: 'genshtab_10k_pilsetas/{z}/{x}/{y}.png',
                },
                {
                    years: '1947',
                    name: 'Геренальный штаб (O Grid)',
                    scale: '1:25K',
                    code: 'gen4',
                    zoom: 15,
                    url: 'genshtab_1947_25k/{z}/{x}/{y}.png',
                },
                {
                    years: '1963',
                    name: 'Геренальный штаб (C Grid)',
                    scale: '1:25K',
                    code: 'gen5',
                    zoom: 15,
                    url: 'PSRS_42g_25k/{z}/{x}/{y}.png',
                },
                {
                    years: '1986',
                    name: 'Геренальный штаб (O Grid)',
                    scale: '1:25K',
                    code: 'gen6',
                    zoom: 15,
                    url: 'PSRS_63g_25k/{z}/{x}/{y}.png',
                },
                {
                    years: '1980s',
                    name: 'Геренальный штаб',
                    scale: '1:50K',
                    code: 'gen',
                    zoom: 14,
                    url: 'PSRS_50k/{z}/{x}/{y}.png',
                },
                {
                    years: '1980s',
                    name: 'Геренальный штаб',
                    scale: '1:100K',
                    code: 'gen',
                    zoom: 13,
                    url: 'PSRS_100k/{z}/{x}/{y}.png',
                },
                {
                    years: '1980s',
                    name: 'Геренальный штаб',
                    scale: '1:200K',
                    code: 'gen',
                    zoom: 12,
                    url: 'PSRS_200k/{z}/{x}/{y}.png',
                },
                {
                    years: '1980s',
                    name: 'Геренальный штаб',
                    scale: '1:500K',
                    code: 'gen',
                    zoom: 11,
                    url: 'PSRS_500k/{z}/{x}/{y}.png',
                },
                {
                    years: '1980s',
                    name: 'Геренальный штаб',
                    scale: '1:1M',
                    code: 'gen',
                    zoom: 10,
                    url: 'PSRS_1M/{z}/{x}/{y}.png',
                },
            ]
        },
        {
            name: '3 verstu / Военно-топографическая',
            layers: [
                {
                    years: '1880',
                    name: 'Военно-топографическая (RU)',
                    code: 'vo1',
                    zoom: 13,
                    url: 'voenno_topo_3verstu/1880ru/{z}/{x}/{y}.png',
                },
                {
                    years: '1915',
                    name: 'Военно-топографическая (RU)',
                    code: 'vo2',
                    zoom: 13,
                    url: 'voenno_topo_3verstu/1915ru/{z}/{x}/{y}.png',
                },
                {
                    years: '1915',
                    name: 'Военно-топографическая (DE, greif)',
                    code: 'vo3',
                    zoom: 13,
                    url: 'voenno_topo_3verstu/1915de_greif/{z}/{x}/{y}.png',
                },
                {
                    years: '1915',
                    name: 'Военно-топографическая (DE, loc)',
                    code: 'vo4',
                    zoom: 13,
                    url: 'voenno_topo_3verstu/1915de_loc/{z}/{x}/{y}.png',
                },
                {
                    years: '1915',
                    name: 'Военно-топографическая (DE, uw)',
                    code: 'vo5',
                    zoom: 13,
                    url: 'voenno_topo_3verstu/1915de_uw/{z}/{x}/{y}.png',
                },
            ]
        },
        {
            name: 'KDWR',
            layers: [
                {
                    name: 'KDWR LNB1',
                    scale: '1:100k',
                    code: 'kdwr1',
                    zoom: 14,
                    url: 'kdwr_100k/tiles_lnb1/{z}/{x}/{y}.png',
                },
                {
                    name: 'KDWR LNB2',
                    scale: '1:100k',
                    code: 'kdwr2',
                    zoom: 14,
                    url: 'kdwr_100k/tiles_lnb2/{z}/{x}/{y}.png',
                },
                {
                    name: 'KDWR LNB3',
                    scale: '1:100k',
                    code: 'kdwr3',
                    zoom: 14,
                    url: 'kdwr_100k/tiles_lnb3/{z}/{x}/{y}.png',
                },
                {
                    name: 'KDWR LNB4',
                    scale: '1:100k',
                    code: 'kdwr4',
                    zoom: 14,
                    url: 'kdwr_100k/tiles_lnb4/{z}/{x}/{y}.png',
                },
                {
                    name: 'KDWR LNB5',
                    scale: '1:100k',
                    code: 'kdwr5',
                    zoom: 14,
                    url: 'kdwr_100k/tiles_lnb5/{z}/{x}/{y}.png',
                },
                {
                    name: 'KDWR Bruno',
                    scale: '1:100k',
                    code: 'kdwrb',
                    zoom: 14,
                    url: 'kdwr_100k/tiles_bruno/{z}/{x}/{y}.png',
                },
                {
                    name: 'KDWR NR',
                    scale: '1:100k',
                    code: 'kdwrn',
                    zoom: 14,
                    url: 'kdwr_100k/tiles_nr/{z}/{x}/{y}.png',
                },
            ]
        },
        {
            name: 'Pilsētu Plāni',
            layers: [
                {
                    years: '1967',
                    name: 'Gouvernements-Stadt Riga. Wieckmann',
                    scale: '',
                    code: 'gsrw',
                    zoom: 17,
                    url: '1867_Gouvernements-Stadt_Riga_Wieckmann/{z}/{x}/{y}.png',
                },
                {
                    years: '1883',
                    name: 'Karte der stadt Riga',
                    scale: '1:4200',
                    code: 'r',
                    zoom: 18,
                    url: '1883_Karte_der_stadt_Riga/{z}/{x}/{y}.png',
                },
                {
                    years: '1885',
                    name: 'Stadplan von Riga',
                    scale: '1:12600',
                    code: 'i',
                    zoom: 17,
                    url: '1885_stadplan_von_riga/{z}/{x}/{y}.png',
                },
                {
                    years: '1937',
                    name: 'Rīga',
                    scale: '1:10K',
                    code: 'r37',
                    zoom: 17,
                    url: 'riga_1937/{z}/{x}/{y}.png',
                },
                {
                    years: '1990s',
                    name: 'Rīga',
                    scale: '1:10K',
                    code: 'r93',
                    zoom: 17,
                    url: 'riga_1990/{z}/{x}/{y}.png',
                },
                {
                    years: '1926',
                    name: 'Cēsis',
                    scale: '',
                    code: 'ce',
                    zoom: 17,
                    url: 'cesis_1926/{z}/{x}/{y}.png',
                },
                {
                    years: '1798',
                    name: 'Valmiera',
                    scale: '',
                    code: 'va',
                    zoom: 18,
                    url: '1798_Valmiera/{z}/{x}/{y}.png',
                },
                {
                    years: '1936',
                    name: 'Rīgas pilsētas plāns',
                    scale: '1:24K',
                    code: 're',
                    zoom: 15,
                    url: '1936_riga_ernst_plates_24k/{z}/{x}/{y}.png',
                },
            ]
        },
        {
            name: 'Mobilo tīklu pārklājums',
            layers: [
                {
                    name: 'LMT 5G',
                    code: 'lmt5g',
                    type: 'WMS',
                    zoom: 18,
                    url: 'https://karte.lmt.lv/geoserver/gwc/service/wms?',
                    extras: {
                        layers: 'lmt:internet_5g',
                        format: 'image/png',
                        opacity: 0.7,
                        attribution: '<a href="https://karte.lmt.lv/">LMT Pārklājuma karte</a>'
                    },
                },
                /* eslint-disable */
                {
                    name: 'TELE2 5G N78',
                    code: 'tele25g',
                    url: 'https://mim.tele2.com/MIMCS/api/Tile/GetOverlay?x={x}&y={y}&z={z}&viewType=1&serviceThresholdIds=78&countryCode=&currentServiceLayerNo=100"',
                    zoom: 16,
                    extras: {
                        opacity: 0.7,
                        attribution: '<a href="https://www.tele2.lv/karte/">TELE2 MIM</a>'
                    },
                },
                /* eslint-enable */
                {
                    name: 'BITE 5G',
                    code: 'bite5g',
                    url: 'https://tiles.anrijs.lv/parklajums/bite/latest_5g/{z}/{x}/{y}.png',
                    zoom: 10,
                    extras: {
                        opacity: 0.5,
                    },
                },
            ]
        },
    ],
};

export {sources};
